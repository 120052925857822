import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { useMatch, useLocation, useNavigate } from "react-router-dom";

import Header from "./header";
import Steps from "./steps";
import Authentication from "./authentication";
import CaptureAnalyticsContainer from "./analytics";
import SignerIdentityService from "./signer_identity_service";
import { SECONDARY_STEP, SELECT_ID_STEP } from "./constants";

class CaptureApp extends Component {
  constructor(props) {
    super(props);
    const { captureAnalytics } = this.props;
    const si = this.props.siId || null;

    this._isMounted = false;
    this.state = { authenticated: false, secondaryIdRequired: false };
    this.signerIdentityService = new SignerIdentityService(si);

    captureAnalytics.trackSignerEnterCaptureApp({ signerIdentityId: si });
  }

  componentDidMount() {
    this._isMounted = true;

    this.signerIdentityService
      .secondaryIdRequired()
      .then((secondaryIdRequired) => {
        this._isMounted && this.setState({ secondaryIdRequired });
      })
      .catch(() => {});
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  authenticate = () => {
    const { navigate, location } = this.props;
    const currentPath = location.pathname.split("/"); // routes typically come in like "/path1/path2"
    const currentPathExists = currentPath && currentPath.length > 2;
    const initializedWithSecondaryId = currentPathExists && currentPath[1] === SECONDARY_STEP;
    this._isMounted &&
      this.setState(
        (prevState) => ({
          authenticated: true,
          secondaryIdRequired: prevState.secondaryIdRequired || initializedWithSecondaryId,
        }),
        () => {
          const basePath = currentPathExists ? currentPath[1] : SELECT_ID_STEP;
          navigate(`/${basePath}/${this.signerIdentityService.getId()}`);
        },
      );
  };

  render() {
    const { authenticated, secondaryIdRequired } = this.state;
    const { children, location, captureAnalytics } = this.props;

    return (
      <div className="CaptureApp">
        {authenticated ? (
          <div className="CaptureApp--Container">
            <Header />
            <Steps location={location} secondaryIdRequired={secondaryIdRequired} />
            <div className="CaptureApp--Content">
              {children({
                signerIdentityService: this.signerIdentityService,
                captureAnalytics,
              })}
            </div>
          </div>
        ) : (
          <div>
            <Header />
            <Authentication
              signerIdentityService={this.signerIdentityService}
              onSuccess={this.authenticate}
              captureAnalytics={captureAnalytics}
            />
          </div>
        )}
      </div>
    );
  }
}

CaptureApp.propTypes = {
  children: PropTypes.func.isRequired,
  siId: PropTypes.string,
  // CaptureAnalyticsContainer
  captureAnalytics: PropTypes.shape({
    trackSignerEnterCaptureApp: PropTypes.func.isRequired,
  }),
};

const CaptureAppWithAnalytics = ({ children }) => {
  const match = useMatch("/:step/:si");
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <CaptureAnalyticsContainer>
      {(captureAnalytics) => (
        <CaptureApp
          captureAnalytics={captureAnalytics}
          location={location}
          navigate={navigate}
          siId={match?.params.si}
        >
          {children}
        </CaptureApp>
      )}
    </CaptureAnalyticsContainer>
  );
};

export default CaptureAppWithAnalytics;
