import {
  forwardRef,
  type ReactNode,
  type CSSProperties,
  type ComponentPropsWithoutRef,
  type ReactElement,
  type Ref,
  type MouseEvent,
} from "react";
import classnames from "classnames";

import { captureBreadcrumb } from "util/exception";
import SmallLoader from "common/core/loading_indicator/small";
import TooltipOverlay from "common/core/tooltip/overlay";
import Icon from "common/core/icon";
import useBrandedStyle from "common/core/brand/org_brand_style";

import Styles from "./index.module.scss";

// Prevents passing `aria-haspopup` without `aria-expanded`, as the two go hand in hand.
// `aria-expanded` may be used for other aria types.
export type AriaPopoutProps =
  | { "aria-haspopup": true; "aria-expanded": boolean }
  | { "aria-haspopup"?: never; "aria-expanded"?: boolean };

export type ButtonVariants = "primary" | "secondary" | "tertiary";
export type ButtonColors = "action" | "danger" | "light" | "dark";
export type ButtonSizes = "large" | "condensed";

export type NotarizeButtonProps = {
  children: ReactNode;
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  fullwidth?: boolean;
  style?: CSSProperties;
  automationId?: string;
  "data-automation-id"?: string;
  disabledHint?: ReactNode;
  disabledHintPlacement?: ComponentPropsWithoutRef<typeof TooltipOverlay>["placement"];
  withIcon?: {
    name: string;
    placement: "right" | "left";
  };
  /** Determines the style of the button */
  variant?: ButtonVariants;
  /** Determines the color of the button */
  buttonColor?: ButtonColors;
  /** Determines the size of the button */
  buttonSize?: ButtonSizes;
};
type BaseButtonProps = Omit<
  ComponentPropsWithoutRef<"button">,
  "children" | "aria-expanded" | "aria-haspopup"
>;
type ButtonProps = BaseButtonProps & AriaPopoutProps & NotarizeButtonProps;
/** Special helper for omitting props from button while not breaking the AriaPopoutProps invariants */
export type ButtonPropsOmit<O extends string> = Omit<BaseButtonProps & NotarizeButtonProps, O> &
  AriaPopoutProps;

// When rendering a tooltip, we need a relative positioned parent to
// place the tooltip correctly
export function TooltipButtonContainer({
  children,
  hoverLabel,
  fullWidth,
}: {
  children: ReactElement;
  hoverLabel: string | ReactNode;
  fullWidth: boolean;
}) {
  return hoverLabel ? (
    <div className={classnames(Styles.tooltipButtonContainer, fullWidth && Styles.fullWidth)}>
      {children}
    </div>
  ) : (
    children
  );
}

const Button = forwardRef(
  (
    {
      children,
      className,
      isLoading,
      onClick,
      disabled,
      type = "button",
      fullwidth,
      automationId = "button",
      style: baseStyle,
      id,
      disabledHint,
      disabledHintPlacement = "top",
      withIcon,
      variant,
      buttonColor,
      buttonSize,
      ...props
    }: ButtonProps,
    ref?: Ref<HTMLButtonElement>,
  ) => {
    const style = {
      ...useBrandedStyle({ linkButton: variant === "tertiary" }),
      ...baseStyle,
    };

    const loader =
      variant !== "primary" && buttonColor === "danger" ? (
        <SmallLoader color="red" />
      ) : variant !== "primary" && buttonColor === "dark" ? (
        <SmallLoader color="gray" />
      ) : (variant === "primary" && buttonColor !== "light") ||
        (variant !== "primary" && buttonColor === "light") ? (
        <SmallLoader color="white" />
      ) : (
        <SmallLoader color="blue" />
      );

    const cx = classnames(
      Styles.button,
      className,
      buttonColor && Styles[buttonColor],
      variant && Styles[variant],
      buttonSize && Styles[buttonSize],
      fullwidth && Styles.fullWidth,
      isLoading && Styles.loading,
    );

    function validateOnClick(event: MouseEvent<HTMLButtonElement>) {
      if (disabled) {
        event.preventDefault();
      } // onClick may be undefined if we're using the button as a form submit
      else if (!isLoading && onClick) {
        captureBreadcrumb({
          message: "Core Button Clicked",
          data: {
            className: cx,
            dataAutomationId: automationId,
          },
          category: "ui.click",
        });
        onClick();
      }
    }

    return (
      <TooltipButtonContainer hoverLabel={disabledHint} fullWidth={Boolean(fullwidth)}>
        <>
          <button
            {...props}
            id={id}
            className={cx}
            aria-disabled={Boolean(disabled || isLoading)}
            onClick={validateOnClick}
            type={type} // eslint-disable-line react/button-has-type
            data-automation-id={props["data-automation-id"] || automationId}
            style={style}
            ref={ref}
          >
            {isLoading && <div className={Styles.loaderContainer}>{loader}</div>}
            <div className={classnames(Styles.buttonChildren, withIcon && Styles.withIcon)}>
              {withIcon?.placement === "left" && <Icon name={withIcon.name} />}
              {children}
              {withIcon?.placement === "right" && <Icon name={withIcon.name} />}
            </div>
          </button>
          {disabled && disabledHint && (
            <TooltipOverlay id={id} trigger="hover" placement={disabledHintPlacement}>
              {disabledHint}
            </TooltipOverlay>
          )}
        </>
      </TooltipButtonContainer>
    );
  },
);

export default Button;
