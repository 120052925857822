import { isMobileDevice } from "util/support";

type VideoOptions = {
  deviceId?: string | null | undefined;
  privacyVideoAspectRatio?: boolean;
};

export const DESKTOP_VIDEO_HEIGHT = 720;
export const DESKTOP_VIDEO_WIDTH = 1280;
const ON_MOBILE = isMobileDevice();
const DESKTOP_MIN_VIDEO_HEIGHT = 360;
const DESKTOP_MIN_VIDEO_WIDTH = 640;
const MOBILE_VIDEO_HEIGHT = 200;
const MOBILE_VIDEO_WIDTH = 160;

export function getVideoDeviceConstraints(options?: VideoOptions) {
  return {
    deviceId: options?.deviceId || undefined,
    height: ON_MOBILE
      ? { ideal: MOBILE_VIDEO_HEIGHT }
      : { min: DESKTOP_MIN_VIDEO_HEIGHT, ideal: DESKTOP_VIDEO_HEIGHT },
    width: ON_MOBILE
      ? { ideal: MOBILE_VIDEO_WIDTH }
      : {
          min: DESKTOP_MIN_VIDEO_WIDTH,
          ideal: options?.privacyVideoAspectRatio ? DESKTOP_MIN_VIDEO_WIDTH : DESKTOP_VIDEO_WIDTH,
        },
    frameRate: {
      max: 30,
      ideal: 24,
    },
  };
}
