import { useMemo, type ReactNode } from "react";
import { useIntl, defineMessages } from "react-intl";
import classnames from "classnames";

import Icon from "common/core/icon";
import { IconButton } from "common/core/button/icon_button";

import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  dismissLabel: { id: "4d6107b6-ffc1-4222-8a5f-0a6c93fee85e", defaultMessage: "Dismiss banner" },
});

export type Kind = "danger" | "success" | "info" | "warning" | "announcement";
type Props = {
  /** Changes the type of alert by controlling the visual styles it should take on. */
  kind?: Kind;
  /** Any valid JSX - usually contains the message of the alert and any links or other elements needed. */
  children: ReactNode;
  className?: string;
  /** Toggles the text alignment to be centered rather than left aligned. */
  centerText?: boolean;
  /** Enables displaying the icon, true by default */
  onClose?: () => void;
  /** Changes the alert to take on a banner style instended for use at the top of the page or section */
  banner?: boolean;
  /** Allows a data-automation-id to be specified */
  automationId?: string;
} & (
  | {
      /** Overrides the default icon for the type of alert. (Note: This is just a partial list of options to test.) */
      customIcon?: string;
      customEmoji?: never;
      noIcon?: never;
    }
  | {
      /** Overrides the default emoji for when kind is announcement */
      customEmoji?: string;
      customIcon?: never;
      noIcon?: never;
    }
  | {
      /** Hides icon/emoji */
      noIcon?: boolean;
      customEmoji?: never;
      customIcon?: never;
    }
);

function getIconOrEmoji(
  noIcon: Props["noIcon"],
  customIcon: Props["customIcon"],
  customEmoji: Props["customEmoji"],
  kind: Kind,
): { type: "icon"; name: string } | { type: "emoji"; value: string } | { type: "none" } {
  if (noIcon) {
    return { type: "none" };
  }

  if (customIcon !== undefined) {
    return { type: "icon", name: customIcon };
  }

  if (customEmoji !== undefined) {
    return { type: "emoji", value: customEmoji };
  }

  switch (kind) {
    case "info":
      return { type: "icon", name: "disclaimer" };
    case "warning":
      return { type: "icon", name: "warning" };
    case "success":
      return { type: "icon", name: "success" };
    case "danger":
      return { type: "icon", name: "doc-warning" };
    case "announcement":
      return { type: "emoji", value: "🎉" };
    default:
      return { type: "icon", name: "disclaimer" };
  }
}

function AlertMessage({
  children,
  className,
  kind = "danger",
  centerText,
  customIcon,
  customEmoji,
  noIcon,
  onClose,
  banner,
  automationId = "alert-message",
}: Props) {
  const intl = useIntl();
  const cx = classnames(
    Styles.alertContainer,
    Styles[kind],
    className,
    centerText && Styles.centerText,
    banner && Styles.banner,
    onClose && Styles.extraRightPadding,
  );
  const alertAccessibility = useMemo(() => {
    if (kind === "danger") {
      return { role: "alert", ariaLive: "assertive" } as const;
    }
    return { role: "status", ariaLive: "polite" } as const;
  }, [kind]);

  const iconOrEmoji = getIconOrEmoji(noIcon, customIcon, customEmoji, kind);

  return (
    <div
      role={alertAccessibility.role}
      aria-live={alertAccessibility.ariaLive}
      className={cx}
      data-automation-id={automationId}
    >
      {iconOrEmoji.type === "icon" && (
        <Icon className={Styles.icon} name={iconOrEmoji.name} noMargin />
      )}
      {iconOrEmoji.type === "emoji" && (
        <span className={Styles.emoji} aria-hidden>
          {iconOrEmoji.value}
        </span>
      )}
      <div>{children}</div>
      {onClose && (
        <IconButton
          automationId="dismiss-alert-message"
          className={Styles.closeButton}
          name="x-small"
          onClick={onClose}
          label={intl.formatMessage(MESSAGES.dismissLabel)}
          variant="tertiary"
          buttonColor={kind === "announcement" ? "light" : "dark"}
          buttonSize="condensed"
        />
      )}
    </div>
  );
}

export default AlertMessage;
